<template lang="pug">
  .competitors-tab
    .competitors-tab-header
      //- Car type
      .car-type Compact
      .date-picker
        .tip(v-if="isChangedPrices && isManualMode")
          .yellow-dot
          | {{ $t("price_calendar.calendar.price_modal.price_changes_not_reflected_on_ota") }}
        DatePagination(
          :options="datesByWeeks"
          :value="selectedWeek"
          @change="handleChangeDate"
        )
    .competitors-tab-table
      .competitors-tab-table-header
        .shop
          span {{ $t("price_calendar.filters.shop") }}
          FaIcon(
            :icon="getSortIcon(defaultSortOrder)"
            @click="setSortByShopName"
          )
        .date-wrapper(v-for="date in selectedWeek")
          .date(
            :class="{ saturday: isSaturday(date), sunday: isSunday(date) || japaneseHolidayName(date) }"
            v-b-tooltip.hover
            :title="japaneseHolidayName(date)"
          )
            span {{ groupedFormattedDates[date].shortDate }}
            span {{ groupedFormattedDates[date].weekday }}
          FaIcon(
            :icon="getSortByDateIcon(date)"
            @click="setSortByDate(date)"
          )
      OwnShopPrices(
        :shop-name="shopName"
        :date-range="selectedWeek"
        :is-common-price="isCommonPrice"
        :rental-period="rentalPeriod"
        :current-plan="currentPlan"
        :current-prices="currentPrices"
        :changed-prices="changedPrices"
        :recommended-prices="recommendedPrices"
        :grouped-formatted-dates="groupedFormattedDates"
        @change="$emit('change', $event)"
      )
      .competitors-tab-table-body
        CompetitorData(
          v-for="competitor in sortedCompetitors"
          :key="competitor.id"
          :competitor="competitor"
          :rental-period="rentalPeriod"
          :date-range="selectedWeek"
          :grouped-formatted-dates="groupedFormattedDates"
        )
</template>

<script>
  // misc
  import { reduce, head, last, some, find, sortBy, get } from "lodash-es"
  import { DATE_FNS_DATE_FORMAT, DATE_FNS_SHORT_DATE_FORMAT, COMPETITORS_DEFAULT_SORT_ORDER } from "@/config/constants"
  import {
    startOfWeek,
    endOfWeek,
    eachDayOfInterval,
    isSaturday,
    isSunday,
    format as dateFormat,
    eachWeekOfInterval,
    add as dateAdd,
    startOfDay,
    endOfDay,
    isEqual
  } from "date-fns"
  import { startOfCurrentDate, isManualMode } from "@/helpers/price-calendar"
  import { japaneseHolidayName } from "@/helpers/calendar-header"

  const currentDate = startOfWeek(startOfCurrentDate)
  const maxDate = dateAdd(currentDate, { months: 2 })

  const splittedDates = (startDate, endDate) => {
    const dates = []
    let currentDate = startOfDay(startDate)

    while (currentDate < endOfDay(endDate)) {
      const endOFWeek = dateAdd(currentDate, { days: 6 })

      dates.push(eachDayOfInterval({ start: currentDate, end: endOFWeek }))

      // start of next week
      currentDate = dateAdd(endOFWeek, { days: 1 })
    }

    return dates
  }

  export default {
    components: {
      DatePagination: () => import("./DatePagination"),
      CompetitorData: () => import("./CompetitorData"),
      OwnShopPrices: () => import("./OwnShopPrices")
    },

    props: {
      initialDates: Array,
      filters: Object,
      rentalPeriod: String,
      currentPrices: Object,
      recommendedPrices: Object,
      currentPlan: Object,
      changedPrices: Object,
      competitors: Array,
      isCommonPrice: Boolean
    },

    data() {
      return {
        dates: this.initialDates,
        defaultSortOrder: COMPETITORS_DEFAULT_SORT_ORDER,
        sorting: {
          orderBy: COMPETITORS_DEFAULT_SORT_ORDER,
          asc: true
        }
      }
    },

    computed: {
      isManualMode,

      shopName({ filters }) {
        return filters.shop.name
      },

      isChangedPrices({ groupedFormattedDates, changedPrices }) {
        return some(groupedFormattedDates, ({ fullDate }) => changedPrices[fullDate])
      },

      datesByWeeks({ initialDates }) {
        return splittedDates(...initialDates)
      },

      selectedWeek({ datesByWeeks }) {
        return find(datesByWeeks, dates => some(dates, date => isEqual(date, head(this.dates))))
      },

      groupedFormattedDates({ selectedWeek }) {
        return reduce(
          selectedWeek,
          (obj, date) => {
            obj[date] ||= {}

            obj[date].shortDate = dateFormat(date, DATE_FNS_SHORT_DATE_FORMAT)
            obj[date].weekday = dateFormat(date, "EEEEEE")
            obj[date].fullDate = dateFormat(date, DATE_FNS_DATE_FORMAT)

            return obj
          },
          {}
        )
      },

      sortedCompetitors({ competitors, sorting: { orderBy, asc }, rentalPeriod, defaultSortOrder }) {
        const orderField = orderBy === defaultSortOrder ? defaultSortOrder : `prices.${rentalPeriod}.${orderBy}`
        const sortedCompetitors = sortBy(competitors, competitor => get(competitor, orderField))

        return asc ? sortedCompetitors : sortedCompetitors.reverse()
      }
    },

    watch: {
      initialDates(value) {
        this.dates = value
      }
    },

    methods: {
      isSaturday,
      isSunday,
      japaneseHolidayName,

      setSortByShopName() {
        this.setSort(this.defaultSortOrder)
      },

      setSortByDate(date) {
        this.setSort(this.getFullDate(date))
      },

      setSort(orderBy) {
        // if orderBy field was changed => asc: true
        // if only asc field was changed => change to opposite (true => false, false => true)
        const asc = orderBy !== this.sorting.orderBy ? true : !this.sorting.asc
        this.sorting = { orderBy, asc }
      },

      getFullDate(date) {
        return get(this.groupedFormattedDates, `${date}.fullDate`)
      },

      getSortByDateIcon(date) {
        return this.getSortIcon(this.getFullDate(date))
      },

      getSortIcon(orderBy) {
        let icon = "sort"

        if (this.sorting.orderBy === orderBy) {
          if (this.sorting.asc) {
            icon = "sort-up"
          } else {
            icon = "sort-down"
          }
        }

        return icon
      },

      // need to disable dates by weeks
      disabledDate(date) {
        const days = eachWeekOfInterval({ start: currentDate, end: maxDate })
        const startDate = head(days)
        const endDate = endOfWeek(last(days))

        return date < startDate || date > endDate
      },

      // TODO: memoize
      getTogglerName(competitorId) {
        return `pricePlans${competitorId}`
      },

      togglePricePlan(competitorId) {
        this[this.getTogglerName(competitorId)].toggle()
      },

      handleChangeDate(dates) {
        const [start, end] = dates
        this.dates = [start, end]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  .competitors-tab
    &-header
      align-items: center
      display: flex
      justify-content: space-between

      .car-type
        color: $default-gray
        font-weight: 400

      .date-picker
        display: flex
        justify-content: flex-end

        .tip
          align-items: center
          color: $default-gray
          display: flex
          margin-right: 20px

          .yellow-dot
            +yellow-dot

        ::v-deep
          .datepicker
            padding: 0

    &-table
      &-header
        +table-row
        background-color: $th-background-color
        color: $default-black
        margin-bottom: 10px
        margin-top: 20px

        svg
          color: $default-gray
          cursor: pointer
          font-size: 0.8rem
          margin-left: 5px
          user-select: none

        .date-wrapper
          .date
            &.sunday
              +tooltip($default-red)

      &-body
        height: 290px
        overflow-y: auto

        &::-webkit-scrollbar
          width: 2px

        &::-webkit-scrollbar-thumb
          background-color: $default-gray
          border-radius: 2px
</style>
